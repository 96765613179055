import Vue from 'vue'
import VueRouter from 'vue-router'

// import store from '../store';

//import _404 from '../views/404.vue'
import Article from '../views/Article.vue'
import Blog from '../views/Blog.vue'
//import BlogRoll from '../views/BlogRoll.vue'
import CategoryOverview from '../views/CategoryOverview.vue'
import Category from '../views/Category.vue'
//import DataPrivacy from '../views/DataPrivacy.vue'
//import Imprint from '../views/Imprint.vue'
import Recipe from '../views/Recipe.vue'
import RecipeOverview from '../views/RecipeOverview.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Blog',
    component: Blog,
    meta: {
        title: "Essen wie bei Oma",
    },
  },
  {
    path: '/seite/:page',
    name: 'BlogPage',
    component: Blog,
    props: true,
    meta: {
        title: "Essen wie bei Oma",
    },
  },
  {
    path: '/blog-artikel/:article_name',
    name: 'Artikel',
    component: Article,
    props: true,
    meta: {
        title: "Essen wie bei Oma",
    },
  },
  {
    path: '/rezept-uebersicht/',
    name: 'RezeptUebersicht',
    component: RecipeOverview,
    meta: {
        title: "Essen wie bei Oma",
    },
  },
  {
    path: '/rezept/:recipe_name',
    name: 'Rezept',
    component: Recipe,
    props: true,
    meta: {
        title: "Essen wie bei Oma",
    },
  },
  {
    path: '/kategorie-uebersicht/',
    name: 'KategorieUebersicht',
    component: CategoryOverview,
    meta: {
        title: "Essen wie bei Oma",
    },
  },
  {
    path: '/kategorie/:category_name',
    name: 'Kategorie',
    component: Category,
    props: true,
    meta: {
        title: "Essen wie bei Oma",
    },
  },
  {
    path: '/blogroll/',
    name: 'BlogRoll',
    component: () => import(/* webpackChunkName: "BlogRoll" */ '../views/BlogRoll.vue'),
    meta: {
        title: "Essen wie bei Oma",
    },
  },
  {
    path: '/impressum/',
    name: 'Imprint',
    component: () => import(/* webpackChunkName: "Imprint" */ '../views/Imprint.vue'),
    meta: {
        title: "Essen wie bei Oma",
    },
  },
  {
    path: '/datenschutz/',
    name: 'DataPrivacy',
    component: () => import(/* webpackChunkName: "DataPrivacy" */ '../views/DataPrivacy.vue'),
    meta: {
        title: "Essen wie bei Oma",
    },
  },

  {
    path: '/suche/:searchstring',
    name: 'Searchresult',
    component: () => import(/* webpackChunkName: "DataPrivacy" */ '../views/SearchResult.vue'),
    meta: {
        title: "Essen wie bei Oma",
    },
  },


  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
    meta: {
      title: "404 - Seite nicht gefunden",
/*      accessRights : {
        admin : false,
        login : false,
      }, */
    },
  },


/*
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
*/
//    component: () => import(/* webpackChunkName: "about" */ '../views/Blog.vue')
//  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


const adjust_title = (to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
//    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

    next();
};

router.beforeEach((to, from, next) => {
//    if (insufficientAccessRights(to,from,next)) {
      //  next('/');
  //  } else {
        adjust_title(to,from,next);
    //}
})


export default router
