<template>
            <form class="search" @submit="go_to_search_page">
              <input @focus="search"  @keyup="search" placeholder="Suchen..." v-model="search_input" type="input">
    
              <div style="position: absolute; z-index: 99; background: white; border: 1px solid black; width: 500px; height: 700px; margin-left: -200px;" v-if="dialog == true">
                  <h3>Suchergebnisse</h3>
                  <b>Suche im Blog:</b><br/>
                  <ul>
                      <li v-for="(item, index) in blog" :key="'idx1'+index"><a :href="item.url" @click="jump(item.url)">{{ item.text }}</a> </li>
                  </ul>
                  <b>Suche im Rezeptbuch:</b><br/>
                  <ul>
                      <li v-for="(item2, index2) in recipes" :key="'idx2'+index2"><a :href="item2.url" @click="jump(item2.url)">{{ item2.text }}</a> </li>
                  </ul>
              </div>
            </form>
</template>

<script>
export default {
  name: 'Search',
  data : function() {
    return {
        dialog : false, 
        search_input : "",

        blog : null,
        recipes : null,
    };
  },
  methods : {
    go_to_search_page : function(evt) {
        evt.preventDefault();
        this.dialog = false;
        this.jump('/suche/'+this.search_input);
    },
    jump : function(target) {
      this.$router.push( {path : target } );
      this.dialog = false;
    },
    search : function(evt) {
      if (evt) evt.preventDefault();
      console.log(this.search_input.length);
      if (this.search_input.length < 3) {
          this.dialog = false;
          return;
      }

      // do not show dialog on searchresult page
      if (this.$route.name == "Searchresult") {
          this.dialog = false;
          return;
      }

      this.dialog = true;

      this.$backendapi.call("search/search", this.search_input , response => { 
          //this.blogEntries = response.data;
          this.blog = response.data.blog;
          this.recipes = response.data.recipes;
          console.log(response);
      }, (err) => {
          alert("Fehler: "+err);
      });


    },
  },
}
</script>
