<template>
  <footer class="container">
    <div class="row">
      <div class="col-md-12">
        <ul> 

          <li class="spacer"><router-link class="" to="/">Blog</router-link></li>
          <!--<li class="spacer"><router-link class="" to="/kategorie-uebersicht/">Kochbuch</router-link></li>-->
          <li class="spacer"><router-link class="" to="/rezept-uebersicht/">Rezepte A-Z</router-link></li> 
          <li class="spacer"><router-link class="" to="/kategorie-uebersicht/">Rezepte nach Kategorie</router-link></li> 
          <li class="spacer"><router-link class="" to="/blogroll/">Blogroll</router-link></li>
          <li class="spacer"><router-link class="" to="/impressum/">Impressum</router-link></li>
          <li class="spacer"><router-link class="" to="/datenschutz/">Datenschutzerklärung</router-link></li>
          <li><a href="https://admin.essen-wie-bei-oma.de/">Admin</a></li>
        </ul>
        <div style="float: right; font-size: 12px; padding-top: 4px; padding-right: 4px;">
          ©2012-{{ year }} Björn Mönikes
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterNavBar',
  data : function() {
    return {
        year : new Date().getFullYear(),
    };
  },
}
</script>
