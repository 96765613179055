<template>
<div class="row">

 <div class="col-md-3" v-bind:key="index" v-for="(recipe, index) in recipeList"> 
     <img :src="'/files/'+ recipe.image_preview" class="img-fluid"> <br/> 
     <router-link :to="'/rezept/'+ recipe.name"> {{ recipe.name }} </router-link><br/> 
 </div> 

</div>
</template>

<script>
export default {
  name: 'RecipeOverview',
  data : function() {
    return {
        recipeList : null,
    };
  },
  methods: {
    update : function() {
      this.$backendapi.call("recipe/list",{}, response => { 
          this.recipeList = response.data;
      }, (err) => {
          alert("Fehler: "+err);
      });
    },
  },
  mounted: function() {
    this.update();
  }

}
</script>
