<template>
  <div class="row">
      <div class="col-md-12">
          <div class="blog" v-if="article != null">
              <ul>
                  <div class="blog-date">{{ get_date(article) }}</div>
                  <h2 class="blog-headline"> 
                    <!-- {{ entry.uid }} --> {{ article.headline }}
                  </h2>
                  <div class="blog-body">
                      <div v-html="article.article" />
                      <br/>
                  </div>
                  <div class="blog-footer">
                      Geschrieben von {{ author(article.author_uid) }} im Blog um {{ get_time(article) }} | Kommentare ({{ article.num_comments }})
                  </div>
                  <BlogCommentsList :blog_entry_uid="article.uid" />
              </ul>
              <button @click="$router.go(-1)">Zurück</button>
          </div>
      </div>
  </div>
</template>

<script>

import BlogCommentsList from '@/components/article/BlogCommentsList'

export default {
  name: 'Article',
  components: {
    BlogCommentsList,
  },
  data : function() {
    return {
      article : null,
    };
  },
  methods: {
      author : function(uid) {
          return this.$store.getters.get_author(uid);
      },
      update : function() {
          let name = this.$attrs.article_name;
          this.$backendapi.call("blog/retrieve_by_name", name, response => { 
              this.article = response.data;
          }, (err) => {
              alert("Fehler: "+err);
          });
      },
      get_date : function(entry) {
          if (entry == null) return "NULL";
          var dow = [ "Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag" ];
          var ts = Date.parse(entry.creation_ts);
          var d = new Date(ts);
          var datestring = dow[d.getDay()] +", " 
                           + ("0" + d.getDate()).slice(-2) + "." 
                           + ("0"+(d.getMonth()+1)).slice(-2) + "." 
                           + d.getFullYear();

          //console.log(d);
          return datestring;
      },
      get_time : function(entry) {
          if (entry == null) return "NULL";
          var ts = Date.parse(entry.creation_ts);
          var d = new Date(ts);
          var datestring = ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);

          //console.log(d);
          return datestring;
      },

  },
  mounted: function() {
      this.update();
      this.$store.dispatch("init", {});
  }
}
</script>
