<template>
    <script v-html="jsonld" type="application/ld+json"> </script>
</template>

<script>


export default {
  name: 'RecipeStructuredData',
  props: {
    recipe : Object,
  },
  watch : {
      recipe : function(newVal) {
          console.log(newVal);
      },
  },
  data : function() {
    return {
      current_page : "Hallo Welt",
      num_pages    : null,
      num_entries  : null,
    };
  },
  computed : {
      jsonld : function() {
          console.log(this.recipe);
          let result = `
    {
      "@context": "https://schema.org/",
      "@type": "Recipe",
      "name": "`+this.recipe.name+`",
      "image": [ 
        "https://www.essen-wie-bei-oma.de/files/`+this.recipe.image_preview+`"
      ],
      "author": {
        "@type": "Person",
        "name": "Björn Mönikes"
      },
      "datePublished": "`+ this.recipe.creation_ts.split("T")[0]+`",
      "description": "`+this.recipe.description+`",
      "prepTime": "PT`+this.recipe.preptime+`M",
      "cookTime": "PT`+this.recipe.cooktime+`M",
      "totalTime": "PT`+this.recipe.totaltime+`M",
      "keywords": "`;
      // "datePublished": "`+ new Date(parseInt(this.recipe.creation_ts)*1000).getYear()+`",
      // `2018-03-10" /* this.recipe.creation_ts => Umformen, evtl. FullRecipe erweitern*/,
      for (let kw in this.recipe.keywords) {
          result += kw.keyword_str+`,`;
      }
      result +=`",
      "recipeYield": "`+this.recipe.num_servings+`",
      "recipeCategory": "`+this.recipe.category.text+`",
      "recipeCuisine": "`+this.recipe.cuisine.cuisine_name+`",
`;
      if (this.recipe.nutrition_cal > 0) {
          result +=`
      "nutrition": {
        "@type": "NutritionInformation",
        "calories": "`+this.recipe.nutrition_cal+` calories"
      },
`;
      }
      result+=`
      "recipeIngredient": [`;
          for (let group in this.recipe.ingredients_groups) {
              group = this.recipe.ingredients_groups[group];
              // console.log(group);
              for (let ingredient in group.ingredients) {
                  ingredient = group.ingredients[ingredient];
                  // console.log(ingredient);
                  result = result + `"` + ingredient.amount + ingredient.measurement_unit + " "+ingredient.name+`", `;
              }
          }
          result = result.substring(0, result.length - 2); // remove ", "

          result = result + `],
      "recipeInstructions": [`;


          for (let step in this.recipe.preparation_steps) {
              step = this.recipe.preparation_steps[step];
              result = result + `{
          "@type": "HowToStep",
          "name": "Schritt `+step.step+`",
          "text": "`+step.text+`",
          "url": "https://www.essen-wie-bei-oma.de/rezept/`+this.recipe.name+`#step`+step.step+`"
        }, `;
//          "image": "https://example.com/photos/party-coffee-cake/step1.jpg"  


          }
          result = result.substring(0, result.length - 2); // remove ", "

          result = result + `
      ]`;

          if (this.recipe.rating_count > 0) {
              result = result + `
      ,"aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "`+this.recipe.rating_value+`",
        "ratingCount": "`+this.recipe.rating_count+`"
      }`;
          }
/*,

      "video": {
        "@type": "VideoObject",
        "name": "How to make a Party Coffee Cake",
        "description": "This is how you make a Party Coffee Cake.",
        "thumbnailUrl": [
          "https://example.com/photos/1x1/photo.jpg",
          "https://example.com/photos/4x3/photo.jpg",
          "https://example.com/photos/16x9/photo.jpg"
         ],
        "contentUrl": "http://www.example.com/video123.mp4",
        "embedUrl": "http://www.example.com/videoplayer?video=123",
        "uploadDate": "2018-02-05T08:00:00+08:00",
        "duration": "PT1M33S",
        "interactionStatistic": {
          "@type": "InteractionCounter",
          "interactionType": { "@type": "WatchAction" },
          "userInteractionCount": 2347
        },
        "expires": "2019-02-05T08:00:00+08:00"
      }
*/        
          result+=`
    }
          `;
          return result;
      },
  },
  methods: {
      update : function() {
      },
  },
  mounted: function() {
  }
}
</script>

