<template>
    <div v-if="num_pages != null" class="blog-page-selector">

        <router-link v-if="current_page > 1" :to="'/seite/'+ (current_page - 1)">vorherige Seite</router-link>  
        | Seite {{ current_page }} / {{ num_pages }} ({{ num_entries }} Einträge)| 
        
        <router-link v-if="current_page < num_pages " :to="'/seite/'+ (current_page + 1)">nächste Seite</router-link>  
    </div>
    <div v-else>
       Loading
    </div>

</template>

<script>


export default {
  name: 'BlogPageSelector',
  props: {
    attrs_page : Number,
  },
  watch : {
      attrs_page : function(newVal) {
          this.current_page = parseInt(newVal);
          this.$emit('blog-page-changed', this.current_page);
      },
  },
  data : function() {
    return {
      current_page : null,
      num_pages    : null,
      num_entries  : null,
    };
  },
  methods: {
      update : function() {
          this.$backendapi.call("blog/info",{}, response => { 
              // console.log( response);
              this.num_pages = response.num_pages;
              this.num_entries = response.num_entries;
              this.$emit('blog-page-changed', this.current_page != undefined ? this.current_page : 1);
          }, (err) => {
              alert("Fehler: "+err);
          });

      },
  },
  mounted: function() {
      if (this.attrs_page != null) {
          this.current_page = parseInt(this.attrs_page);
      } else if (this.current_page == undefined) {
        this.current_page = 1;
      }
      this.update();
  }
}
</script>

