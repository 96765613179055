<template>
<div class="col-md-12">


<div class="blog">
    <ul>
      <li v-for="(entry, index) in blogEntries" v-bind:key="index" class="clearfix">
        <div class="blog-date">{{ get_date(entry) }} </div>
          <h2 class="blog-headline">
            <router-link :to="'/blog-artikel/'+entry.headline">  
              <!-- {{ entry.uid }} --> {{ entry.headline }}
            </router-link>
          </h2>

<!--<pre>{{ entry }}</pre> -->

          <div class="blog-body"> <div v-html="entry.teaser" />
            <a style="float: none;" :href="'/blog-artikel/'+ entry.headline ">[...]</a>
          </div>
          <div class="blog-footer">Geschrieben von {{ author(entry.author_uid) }} im Blog um {{ get_time(entry) }} | <a :href="'/blog-artikel/'+ entry.headline +'/#kommentare'">Kommentare ({{ entry.num_comments }})</a></div>
       </li>
      </ul>
</div>

        </div>
</template>

<script>


export default {
  name: 'BlogEntries',
  props: ['current_page'],
  data : function() {
    return {
      blogEntries : null,
    };
  },
  watch: {
    current_page: function (new_current_page) {
 //       alert("BlogEntries watch: update to new page #"+new_current_page);
        this.update(new_current_page);
    },
  },
  computed : {
  },
  methods: {
      author : function(uid) {
          return this.$store.getters.get_author(uid);
      },
      update : function(page) {
          this.$backendapi.call("blog/get_blogentries",page, response => { 
              this.blogEntries = response.data;
          }, (err) => {
              alert("Fehler: "+err);
          });
      },
      get_date : function(entry) {
          var dow = [ "Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag" ];
          var ts = Date.parse(entry.creation_ts);
          var d = new Date(ts);
          var datestring = dow[d.getDay()] +", " 
                           + ("0" + d.getDate()).slice(-2) + "." 
                           + ("0"+(d.getMonth()+1)).slice(-2) + "." 
                           + d.getFullYear();

          //console.log(d);
          return datestring;
      },
      get_time : function(entry) {
          var ts = Date.parse(entry.creation_ts);
          var d = new Date(ts);
          var datestring = ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);

          //console.log(d);
          return datestring;
      },
  },
  mounted: function() {
//      this.update();
//      alert("BlogEntries mounted:" + this.current_page);
  },
  updated: function() {
//    alert(this.$attr);
//      alert("BlockEntries updated " + this.current_page);
  },
  
}
</script>
