<template>

    <div>
        <a id="kommentare"></a>
        <div class="blog-comment"> Kommentare </div>
        <div class="blog-comment-list clearfix">
            <ul>
                <li v-for="comment in comments" :key="comment.uid">{{ comment.username}} <b> meinte am </b> {{ comment.creation_ts }} um {{ comment.creation_ts }} 
                    <p> {{ comment.message }} </p>
                    <hr> 
                </li>
            </ul>
        </div>

        <NewCommentForm :blog_entry_uid="blog_entry_uid" />
    </div>
</template>

<script>

import NewCommentForm from '@/components/article/NewCommentForm'

export default {
  name: 'BlogCommentsList',
  components: {
    NewCommentForm,
  },
  props : {
      blog_entry_uid : Number,
  },
  data : function() {
    return {
      comments : null,
    };
  },
  methods: {
      update : function() {
          this.$backendapi.call("blog_comment/list", this.blog_entry_uid, response => { 
              this.comments = response.data;
          }, (err) => {
              alert("Fehler: "+err);
          });

      },
  },
  mounted: function() {
      this.update();
  }
}
</script>
