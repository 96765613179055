<template>
<div v-if="recipe != null" class="row">
    <RecipeStructuredData v-bind:recipe="recipe" />
    <div class="col-md-4">
        <!-- <h1>{{ recipe.uid }}</h1> -->
        Zutaten
      <div v-for="(group, index) in recipe.ingredients_groups" v-bind:key="index">
        <h2> {{ group.name }} </h2> 
        <table>          
            <tbody>
                <tr v-for="(ingredient, index2) in group.ingredients" v-bind:key="index2">
                    <td><span v-if="ingredient.amount != 0">{{ ingredient.amount }}{{ ingredient.measurement_unit }}</span></td>
                    <td>{{ ingredient.name }} </td>
                </tr>
            </tbody>
        </table>
      </div>

      <div v-if="recipe.hint != null">
          <h3> Zutatentipp:</h3>
          <div> {{ recipe.hint }} </div>
      </div>
    </div>
    <div class="col-md-8">
       <h1>{{ recipe.name }}</h1>
           <img :src="'/files/' + recipe.image_recipeview" class="img-fluid"><br/>
       <br/>
       <img href="#"> <br>
       <div v-for="(step, index3) in recipe.preparation_steps" v-bind:key="index3">
         {{ step.step }}. 
         {{ step.text }}
       </div>
    </div>
    <button @click="$router.go(-1)">Zurück</button>
</div>
</template>

<script>

import RecipeStructuredData from "@/components/recipe/RecipeStructuredData";

export default {
  name: 'Recipe',
  components : {
    RecipeStructuredData,
  },
  data : function() {
    return {
        recipe : null,
    };
  },
  methods: {
    update : function() {

//console.log(this.$attrs.recipe_uid);
// console.log(this.$attrs);

      this.$backendapi.call("recipe/retrieve_by_name",this.$attrs.recipe_name, response => { 
          this.recipe = response.data;
      }, (err) => {
          alert("Fehler: "+err);
      });
    },
  },
  mounted: function() {
    this.update();
  }

}
</script>
