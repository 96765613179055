//______________________________________________________________________________
//   |          |
//   | moenikes |           Bjoern Moenikes Software Entwicklung
//    ----------
//
//
//    KSM-NG-Frontend
//
//    Copyright (C) by Björn Mönikes
//
//    initial author:    Björn Mönikes
//    last change:
//

import axios from 'axios';


const blog = {
    /**
     * Retrieve a list of all users.
     *
     * @async
     * @param  {object}   backendapi        - A reference to the backendapi which is injected via the call function
     * @param  {function} onSuccess         - onSuccess callback handler
     * @param  {function} onFailure         - onFailure callback handler
     * @return {void}                       - 
     */
   info(backendapi, __empty__, onSuccess, onFailure) {
        return axios.get(backendapi.get_api_base_url() + 'blog/info', {}, {withCredentials: true}).then(
            response => {
                // window.console.log("admin_get_userlist: axios response:");
                // window.console.log(response.headers);
                if (response.data.success == true) {
                    onSuccess(response.data)
                } else {
                    onFailure(response.data.message)
                }
            },
            (err) => {
                onFailure(err)
            });
    },




    /**
     * Retrieve a list of all users.
     *
     * @async
     * @param  {object}   backendapi        - A reference to the backendapi which is injected via the call function
     * @param  {function} onSuccess         - onSuccess callback handler
     * @param  {function} onFailure         - onFailure callback handler
     * @return {void}                       - 
     */
   get_blogentries(backendapi, page, onSuccess, onFailure) {
        return axios.get(backendapi.get_api_base_url() + 'blog/list/paged/'+page, {}, {withCredentials: true}).then(
            response => {
                // window.console.log("admin_get_userlist: axios response:");
                // window.console.log(response.headers);
                if (response.data.success == true) {
                    onSuccess(response.data)
                } else {
                    onFailure(response.data.message)
                }
            },
            (err) => {
                onFailure(err)
            });
    },


    /**
     * Retrieve a list of all users.
     *
     * @async
     * @param  {object}   backendapi        - A reference to the backendapi which is injected via the call function
     * @param  {function} onSuccess         - onSuccess callback handler
     * @param  {function} onFailure         - onFailure callback handler
     * @return {void}                       -
     */
   retrieve(backendapi, uid, onSuccess, onFailure) {
        return axios.get(backendapi.get_api_base_url() + 'blog/retrieve/'+uid, {}, {withCredentials: true}).then(
            response => {
                // window.console.log("admin_get_userlist: axios response:");
                // window.console.log(response.headers);
                if (response.data.success == true) {
                    onSuccess(response.data)
                } else {
                    onFailure(response.data.message)
                }
            },
            (err) => {
                onFailure(err)
            });
    },


    /**
     * Retrieve a list of all users.
     *
     * @async
     * @param  {object}   backendapi        - A reference to the backendapi which is injected via the call function
     * @param  {function} onSuccess         - onSuccess callback handler
     * @param  {function} onFailure         - onFailure callback handler
     * @return {void}                       -
     */
   retrieve_by_name(backendapi, name, onSuccess, onFailure) {
        return axios.get(backendapi.get_api_base_url() + 'blog/retrieve-by-name/'+name, {}, {withCredentials: true}).then(
            response => {
                // window.console.log("admin_get_userlist: axios response:");
                // window.console.log(response.headers);
                if (response.data.success == true) {
                    onSuccess(response.data)
                } else {
                    onFailure(response.data.message)
                }
            },
            (err) => {
                onFailure(err)
            });
    },


    /**
     * Retrieve a list of all users.
     *
     * @async
     * @param  {object}   backendapi        - A reference to the backendapi which is injected via the call function
     * @param  {function} onSuccess         - onSuccess callback handler
     * @param  {function} onFailure         - onFailure callback handler
     * @return {void}                       -
     */
   author_list(backendapi, uid, onSuccess, onFailure) {
        return axios.get(backendapi.get_api_base_url() + 'blog/author-list', {}, {withCredentials: true}).then(
            response => {
                // window.console.log("admin_get_userlist: axios response:");
                // window.console.log(response.headers);
                if (response.data.success == true) {
                    onSuccess(response.data)
                } else {
                    onFailure(response.data.message)
                }
            },
            (err) => {
                onFailure(err)
            });
    },
	
}



export default blog
