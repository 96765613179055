<template>
  <div class="col-md-12">

    <BlogEntries v-bind:current_page="current_page" />

    <BlogPageSelector v-on:blog-page-changed="page_changed" :attrs_page="$attrs.page" />

  </div>
</template>

<script>

import BlogEntries from '@/components/blog/BlogEntries'
import BlogPageSelector from '@/components/blog/BlogPageSelector'

export default {
  name: 'Blog',
  components: {
    BlogEntries,
    BlogPageSelector,
  },
  data : function() {
    return {
      current_page : this.$attrs.page ? this.$attrs.page : null,
    };
  },
  methods: {
      page_changed : function(page) { 
          console.log("Page changed to "+page);
          this.current_page = page;
      },
      update : function() {
/*          this.$backendapi.call("blog/get_blogentries",page, response => { 
              this.blogEntries = response.data;
          }, (err) => {
              alert("Fehler: "+err);
          });
*/
      },
  },
  mounted: function() {
      this.$store.dispatch("init", {});
//      this.update();
//    this.current_page = this.$attrs.page;
  },
  updated: function() {
//     alert("Blog Updated");
  },
  
}
</script>
