<template>
<div class="row">

 <div class="col-md-3" v-bind:key="index" v-for="(recipe, index) in recipeList"> 
     <img :src="'/files/'+ recipe.image_preview" class="img-fluid"> <br/>      
     <router-link :to="'/rezept/'+ recipe.name"> {{ recipe.name }} </router-link><br/> 
 </div> 

</div>
</template>

<script>
export default {
  name: 'Category',
  data : function() {
    return {
        recipeList : null,
    };
  },
  methods: {
    update : function(category_name) {

      this.$backendapi.call("recipe/list_by_category/",category_name, response => { 
          this.recipeList = response.data;
console.log(response.data);
      }, (err) => {
          alert("Fehler: "+err);
      });

    },
  },
  mounted: function() {
    var catname = this.$attrs.category_name;
    console.log(catname);
    this.update(catname);
  }

}
</script>
