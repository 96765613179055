//______________________________________________________________________________
//   |          |
//   | moenikes |           Bjoern Moenikes Software Entwicklung
//    ----------
//
//
//    KSM-NG-Frontend
//
//    Copyright (C) by Björn Mönikes
//
//    initial author:    Björn Mönikes
//    last change:
//

import axios from 'axios';


const recipe = {
    /**
     * List mailboxes for given emaildomain.
     *
     * @async
     * @param  {object}   backendapi        - A reference to the backendapi which is injected via the call function
     * @param  {string}   emaildomain       - The emaildomain for which the list of mailboxes shall be loaded
     * @param  {function} onSuccess         - onSuccess callback handler
     * @param  {function} onFailure         - onFailure callback handler
     * @return {void}
     */
    list: function(backendapi, emaildomain, onSuccess, onFailure) {
        return axios.get(backendapi.get_api_base_url() + 'recipe/list/', {}, {withCredentials: true}).then(
            response => {
                // window.console.log("admin_get_userlist: axios response:");
                // window.console.log(response.headers);
                if (response.data.success == true) {
                    onSuccess(response.data)
                } else {
                    onFailure(response.data.message)
                }
            },
            (err) => {
                onFailure(err)
            });
    },



    /**
     * Add a new email mailbox.
     *
     * @async
     * @param  {object}   backendapi               - A reference to the backendapi which is injected via the call function
     * @param  {object}   data                     - An objected with the new data
     * @param  {string}   data.emaildomainname     - The realname of the user which shall be created
     * @param  {string}   data.username            - The username of the user which shall be created
     * @param  {string}   data.password            - The password of the user which shall be created
     * @param  {function} onSuccess                - onSuccess callback handler
     * @param  {function} onFailure                - onFailure callback handler
     * @return {void}
     */
    retrieve: function(backendapi, uid, onSuccess, onFailure) {
        return axios.get(backendapi.get_api_base_url() + 'recipe/retrieve/'+uid, {}, {withCredentials: true}).then(
            response => {
                // window.console.log("admin_get_userlist: axios response:");
                // window.console.log(response.headers);
                if (response.data.success == true) {
                    onSuccess(response.data)
                } else {
                    onFailure(response.data.message)
                }
            },
            (err) => {
                onFailure(err)
            });
    },


    /**
     * Add a new email mailbox.
     *
     * @async
     * @param  {object}   backendapi               - A reference to the backendapi which is injected via the call function
     * @param  {object}   data                     - An objected with the new data
     * @param  {string}   data.emaildomainname     - The realname of the user which shall be created
     * @param  {string}   data.username            - The username of the user which shall be created
     * @param  {string}   data.password            - The password of the user which shall be created
     * @param  {function} onSuccess                - onSuccess callback handler
     * @param  {function} onFailure                - onFailure callback handler
     * @return {void}
     */
    retrieve_by_name: function(backendapi, name, onSuccess, onFailure) {
        return axios.get(backendapi.get_api_base_url() + 'recipe/retrieve-by-name/'+name, {}, {withCredentials: true}).then(
            response => {
                // window.console.log("admin_get_userlist: axios response:");
                // window.console.log(response.headers);
                if (response.data.success == true) {
                    onSuccess(response.data)
                } else {
                    onFailure(response.data.message)
                }
            },
            (err) => {
                onFailure(err)
            });
    },





    /**
     * Add a new email mailbox.
     *
     * @async
     * @param  {object}   backendapi               - A reference to the backendapi which is injected via the call function
     * @param  {object}   data                     - An objected with the new data
     * @param  {string}   data.emaildomainname     - The realname of the user which shall be created
     * @param  {string}   data.username            - The username of the user which shall be created
     * @param  {string}   data.password            - The password of the user which shall be created
     * @param  {function} onSuccess                - onSuccess callback handler
     * @param  {function} onFailure                - onFailure callback handler
     * @return {void}
     */
    list_by_category: function(backendapi, name, onSuccess, onFailure) {
        return axios.get(backendapi.get_api_base_url() + 'recipe/list-by-category/'+name, {}, {withCredentials: true}).then(
            response => {
                // window.console.log("admin_get_userlist: axios response:");
                // window.console.log(response.headers);
                if (response.data.success == true) {
                    onSuccess(response.data)
                } else {
                    onFailure(response.data.message)
                }
            },
            (err) => {
                onFailure(err)
            });
    },


}


export default recipe
