<template>
  <div v-if="comment != null">
        <form style="margin-top: 42px;" v-if="edit_mode">
            <label for="">Name*</label> <input type="text" v-model="comment.username">        <br>
            <label for="">E-Mail*</label> <input type="text" v-model="comment.email">     <br>
            <label for="">Homepage</label> <input type="text" v-model="comment.homepage"> <br>
            <label for="">Kommentar*</label> <textarea v-model="comment.message"></textarea>     
            <br>
            <label for=""></label>  <!--<input type="submit" value="Vorschau anzeigen" name="preview" />-->
            <button @click="insert">Kommentar eintragen</button><br>
            * Pflichtfelder:<br>
            Name, E-Mail und Kommentar sind Pflichtfelder. Ihre E-Mail-Adresse wird nicht angezeigt.<br>
        </form>

        <div v-if="! edit_mode">
          <div style="margin: 80px auto;">
            <h3>Ihr Kommentar wurde abgeschickt und muss nun noch durch einen Admin freigegeben werden!</h3>
            <br/><br/>
            <button @click="init">Ok</button>
          </div>
        </div>
  </div>
</template>

<script>

//import BlogPageSelector from '@/components/blog/BlogPageSelector'

export default {
  name: 'NewCommentForm',
  components: {
//    BlogPageSelector,
  },
  props : {
      blog_entry_uid : Number,
  },
  data : function() {
    return {
      comment   : null,
      edit_mode : true,
    };
  },
  methods: {
      insert : function(evt) {
          if (evt) evt.preventDefault();
          console.log(this.comment);

//          let name = this.$attrs.article_name;
          this.$backendapi.call("blog_comment/new", this.comment, response => { 
              console.log(response);
              this.edit_mode = false;
          }, (err) => {
              alert("Fehler: "+err);
          });
      },
      init : function() {
          this.comment = {
              "blog_entry_uid" : this.blog_entry_uid,
              "email"          : "",
              "homepage"       : "",
              "username"       : "", 
              "message"        : "",
          };
          this.edit_mode = true;
/*
*/
      },
  },
  mounted: function() {
      this.init();
  }
}
</script>
