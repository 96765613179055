import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        blog_authors : null,  
    },
    mutations: {
        UPDATE : function(state, new_blog_authors) {
            state.blog_authors = [{ "uid":0, "author": "None"}, ...new_blog_authors ];
	}
    },
    actions : {
        init : function(context, params) {
            console.log(context);
            console.log(params);
            if (this.blog_authors == null) {
                this._vm.$backendapi.call("blog/author_list", {},response => {
                    // window.console.log(response.data);
                    context.commit('UPDATE', response.data);
//                    console.log("XXXXXXXXXXXXXXXXXXXXXX");
//                    console.log(response);
                }, (err) => {
                    alert(err);
    /*                this.dispatch('setError',{headline: "Einloggen fehlgeschlagen.", code: null, message: err});
                    console.log("store/auth");
                    console.log(err);
                    context.commit('LOGOUT'); */
                });  // add error handling: https://github.com/axios/axios/issues/960
            }		
	}
    },
    getters: {
        get_author : (state) => (uid) => { 
            if (state.blog_authors != null) {
                return state.blog_authors[uid].author;
            } else { 
                return null;
            }
	}
    },
    modules: {
    }
})
