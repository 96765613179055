<template>
  <div id="app" class="container">
    <div class="container">
      <header class="blog-header py-3">
        <div class="row flex-nowrap justify-content-between align-items-center">
          <div class="col-12 justify-content-end align-items-center">
            <div style="float: right;padding: 2px;"><a href="/feed/"><img src="/resources/rss-feed.png"></a></div>
            <Search />
          </div>
        </div>
      </header>
    </div>
    <div class="container main-box">
      <header class="">
        <div class="row flex-nowrap justify-content-between align-items-center" >
          <div class="col-12 pt-1 title" >
            <span class="logo-text"> <router-link to="/" class="title-url">   essen-wie-bei-Oma.de </router-link></span>
          </div>
        </div>


      </header>
      <div class="submenu">
          <MainNavBar />
      </div>

      <main role="main" class="container">
<!-- MAIN -->
<!--                < ErrorPanel / >   -->
                <router-view></router-view>

      </main>
    </div>
    <div class="container">
      <FooterNavBar />
    </div>
  </div>
</template>

<script>
import FooterNavBar from './components/FooterNavBar.vue'
import MainNavBar from './components/MainNavBar.vue'
import Search from './components/Search.vue'

export default {
  name: 'App',
  components: {
    FooterNavBar,
    MainNavBar,
    Search,
  }
}
</script>

<style>
#app {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
