<template>
<div class="row">

 <div class="col-md-3" v-bind:key="index" v-for="(category, index) in categoryList"> 
     <img :src="'/files/rezepte/'+ category.image_preview" class="img-fluid"> <br/>      
     <router-link :to="'/kategorie/'+ category.text"> {{ category.text }} </router-link><br/> 
 </div> 

</div>
</template>

<script>
export default {
  name: 'CategoryOverview',
  data : function() {
    return {
        categoryList : null,
    };
  },
  methods: {
    update : function() {
      this.$backendapi.call("category/get_list",{}, response => { 
          this.categoryList = response.data;
console.log(response.data);
      }, (err) => {
          alert("Fehler: "+err);
      });
    },
  },
  mounted: function() {
    this.update();
  }

}
</script>
